<template>
  <div class="field">
    <label class="label has-text-primary mb-1">{{ label }}</label>
    <dropdown-select
      :items="items"
      :multiple="multiple"
      :value="value"
      :disabled="isDisabled"
      @input="onNewSelection"
      :is-form-input="true"
    >
      <template #dropdown="{ item }">
        <span>{{ item }} </span>
      </template>
      <template #trigger="{ item, isLast }">
        <span>{{ item }}</span
        ><span v-if="!isLast">, </span>
      </template>

      <template #placeholder>
        <span class="has-text-secondary">{{ placeholder }}</span>
      </template>
    </dropdown-select>
    <template v-if="errorsVisible">
      <p v-if="dropdownRequiredError" class="help is-danger">{{ $t("common.field_required_error") }}</p>
    </template>

    <template v-if="textFieldVisible">
      <div class="control mt-1">
        <input class="input is-simple" type="text" :placeholder="otherInputLabel" v-model="textFieldValue" />
        <template v-if="errorsVisible">
          <p v-if="textFieldRequiredError" class="help is-danger">
            {{ $t("common.field_required_error") }}
          </p>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import FormBuilderConstants from "@/web/views/dev/formbuilder/form-builder-constants";
import DropdownSelect from "@/shared/components/DropdownSelect";
import LpConfigConstants from "@/shared/constants/lp-config-constants";

export default {
  name: "RegisterDropdownInput",

  components: { DropdownSelect },

  props: {
    fieldData: {
      type: Object,
      required: true,
    },
    errorsVisible: {
      type: Boolean,
      required: true,
    },
    validation: {
      type: Object,
      required: true,
    },
    translationsNamespace: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      value: null,
      textFieldValue: "",
      disabled: false,
    };
  },

  computed: {
    fieldId() {
      return this.fieldData.id;
    },

    dropdownRequiredError() {
      const validationKey = FormBuilderConstants.VALIDATIONS.REQUIRED;
      if (this.textFieldVisible) {
        return false;
      } else {
        return validationKey in this.validation && !this.validation[validationKey];
      }
    },

    textFieldRequiredError() {
      const validationKey = FormBuilderConstants.VALIDATIONS.REQUIRED;
      if (this.textFieldVisible) {
        return validationKey in this.validation && !this.validation[validationKey];
      } else {
        return false;
      }
    },

    label() {
      return this.$t(`${this.translationsNamespace}.${this.fieldData.id}_label`);
    },

    placeholder() {
      return this.$t(`${this.translationsNamespace}.${this.fieldData.id}_placeholder`);
    },

    otherInputLabel() {
      return this.$t(`${this.translationsNamespace}.${this.fieldData.id}_other_input_label`);
    },

    items() {
      return Object.values(this.$t(`${this.translationsNamespace}.${this.fieldData.id}_values`));
    },

    multiple() {
      return this.fieldData.multiple || false;
    },

    textFieldItemIndex() {
      return this.fieldData[FormBuilderConstants.FIELD_PROPERTIES.TEXT_FIELD_OPTION_INDEX];
    },

    textFieldVisible() {
      console.log(this.value, this.textFieldItemIndex);
      if (this.value !== null) {
        return this.value === Number.parseInt(this.textFieldItemIndex);
      } else {
        return false;
      }
    },

    currentValues() {
      return { value: this.value, textFieldValue: this.textFieldValue };
    },

    isDisabled() {
      return this.fieldData[FormBuilderConstants.FIELD_PROPERTIES.DISABLED] || this.disabled;
    },
  },

  methods: {
    onNewSelection(newValue) {
      if (newValue !== this.value) {
        this.value = newValue;
        this.textFieldValue = "";
      }
    },

    setConstantSelection(index) {
      this.onNewSelection(index);
      this.disabled = true;
    },
  },

  watch: {
    currentValues(newValue) {
      if (this.multiple) {
        this.$emit(
          "input",
          newValue.value.map(index => this.items[index])
        );
      } else {
        if (this.textFieldVisible) {
          this.$emit("input", newValue.textFieldValue);
        } else {
          this.$emit("input", this.items[newValue.value]);
        }
      }

      this.$emit("form-input");
    },

    fieldData: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          if (FormBuilderConstants.FIELD_PROPERTIES.SELECTED_INDEX in newValue) {
            this.value = newValue[FormBuilderConstants.FIELD_PROPERTIES.SELECTED_INDEX];
          }
        }
      },
    },
  },
};
</script>

<style scoped></style>
