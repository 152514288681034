import { createStyleElement, generateTheme } from "./theme";
import "./scss/theme.scss";
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import WebFont from "webfontloader";

const themeFields = LpConfigConstants.THEME_FIELDS;
const defaultOptions = {
  theme: {
    [themeFields.THEME_TYPE]: LpConfigConstants.THEME_TYPES.SIMPLE_DAY,
    [themeFields.MAIN_COLOR]: "#6784FF",
    [themeFields.ACCENT_COLOR]: "#E79571",
    [themeFields.PRIMARY_TEXT_COLOR]: "#231f20",
    [themeFields.SECONDARY_TEXT_COLOR]: "#000000",
    [themeFields.BACKGROUND_COLOR]: "#ffffff",
  },
};

const styleDomId = "ma-theme-style";

export function install(Vue, isLandingPage) {
  let style = null;
  const opts = Object.assign({}, defaultOptions);

  Vue.prototype.$setThemeColors = function (arg) {
    if (style === null) {
      if (isLandingPage) {
        style = createStyleElement(styleDomId, document.head);
      }
    }

    const themeType = getTheme(arg);
    const fontType = getFont(arg);

    const colors = Object.assign({}, opts.theme, arg);

    let colorsCssCode = generateTheme(colors, themeType);

    //Font initialization
    if (themeType === LpConfigConstants.THEME_TYPES.SIMPLE_DAY) {
      WebFont.load({
        events: false,
        google: {
          families: ["Nunito"],
        },
      });
    } else if(themeType === LpConfigConstants.THEME_TYPES.GOOGLE_CLOUD_SUMMIT){
      WebFont.load({
        events: false,
        custom: {
          families: ["Roboto"],
        },
      });
    } else if (themeType === "iveco") {
      WebFont.load({
        events: false,
        custom: {
          families: ["Arial"],
        },
      });
    } else if (themeType === LpConfigConstants.THEME_TYPES.BUSINESS_DAY) {
      WebFont.load({
        events: false,
        google: {
          families: ["Poppins"],
        },
      });
    } else if (themeType === LpConfigConstants.THEME_TYPES.RZECOMMERCE_2024) {
      WebFont.load({
        events: false,
        google: {
          families: ["Manrope"],
        },
      });
    } else if (themeType === LpConfigConstants.THEME_TYPES.SURVIVAL_RACE_KIDS) {
      WebFont.load({
        events: false,
        custom: {
          families: ["Lively Headline"],
          // urls: ["~@/theme/scss/fonts/srk-font.css"],
        },
      });
    } else if (themeType === LpConfigConstants.THEME_TYPES.CUSTOM_TUV_FONT) {
      WebFont.load({
        events: false,
        google: {
          families: ["Open Sans"],
        },
      });
    } else if (themeType === LpConfigConstants.THEME_TYPES.CUSTOM_MRH_FONT) {
      WebFont.load({
        events: false,
        custom: {
          families: ["Helvetica Neue"],
        },
      });
    } else if (themeType === LpConfigConstants.THEME_TYPES.CUSTOM_IT_TOMORROW_FONT) {
      WebFont.load({
        events: false,
        custom: {
          families: ["Source Sans Pro"],
        },
      });
    }
    else if(themeType === LpConfigConstants.THEME_TYPES.PED_2023) {
      WebFont.load({
        events: false,
        google: {
          families: ["Poppins"],
        },
      });
    } else if(fontType) {
      WebFont.load({
        events: false,
        google: {
          families: [fontType],
        },
      });
    } else {
      WebFont.load({
        events: false,
        google: {
          families: ["Nunito"],
        },
      });
    }
    if (style) {
      style.innerHTML = colorsCssCode;
    }

    if (isLandingPage) {
      document.body.setAttribute("theme", themeType);
      document.body.setAttribute("font", fontType);
      document.documentElement.setAttribute("theme", themeType);
    } else {
      // Widgets with shadowDOM
      this.$el.setAttribute("theme", themeType);
      this.$el.setAttribute("font", fontType);

      const bodyElement = this.$el.querySelector("body");
      if (bodyElement) {
        bodyElement.setAttribute("theme", themeType);
        const styleTheme = createStyleElement(styleDomId, bodyElement);
        styleTheme.innerHTML = colorsCssCode;
        bodyElement.append(styleTheme);
      }
    }
  };
  // Invoke theme generation with default colors
  if (isLandingPage) {
    Vue.prototype.$setThemeColors();
  }
}

function getTheme(value) {
  return (value && value[LpConfigConstants.THEME_FIELDS.THEME_TYPE]) || LpConfigConstants.THEME_TYPES.SIMPLE_DAY;
}

function getFont(value) {
  return (value && value[LpConfigConstants.THEME_FIELDS.FONT]) || LpConfigConstants.FONT_TYPES.NUNITO;
}

export default {
  install,
};
